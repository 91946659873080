import {HttpException} from "../exception";
import {getAuthorization, getJwt} from "./LoginService";
import {CustomerDto} from "../dto/CustomerDto";
import {TreeCustomer} from "../dbo/TreeCustomer";

const ENDPOINT = "/api/v2/customer"


export async function getCustomers(): Promise<CustomerDto[]> {
    const jwt = getJwt();
    const response = await fetch(`${ENDPOINT}/`, {
        headers: {
            "Authorization": `Bearer ${jwt}`
        },
        method: "get"
    });
    if (!response.ok) {
        throw new HttpException(response);
    }
    const result = await response.json();
    return result as CustomerDto[];
}

export async function getCustomerById(customerId: string): Promise<CustomerDto> {
    const response = await fetch(`${ENDPOINT}/${customerId}`, {
        headers: {
            ...getAuthorization()
        },
        method: "get"
    });
    if (!response.ok) {
        throw new HttpException(response);
    }
    const result = await response.json();
    return result as CustomerDto;

}

export async function getCustomersByAncestorId(customerId: string | undefined): Promise<CustomerDto[]> {
    if (!customerId) {
        return [];
    }
    const response = await fetch(`${ENDPOINT}?ancestor=${customerId}`, {
        headers: {
            ...getAuthorization()
        },
        method: "get"
    });
    if (!response.ok) {
        throw new HttpException(response);
    }
    const result = await response.json() as CustomerDto[];
    result.sort((a, b) => a.description.localeCompare(b.description));
    return result as CustomerDto[];
}


export async function saveCustomer(customer: CustomerDto): Promise<CustomerDto> {
    const response = await fetch(`${ENDPOINT}/`,
        {
            method: 'post',
            headers: {
                ...getAuthorization(),
                'content-type': 'application/json'
            },

            body: JSON.stringify(customer)
        });
    if (!response.ok) {
        throw new HttpException(response);
    }
    const result = await response.json();
    return result as CustomerDto;
}

function recSort(customers: CustomerDto[], customerByParent: { [key: string]: TreeCustomer[] }, depth: number): TreeCustomer[] {
    if (!customers) {
        return [];
    }
    let result: TreeCustomer[] = [];
    for (const customer of customers) {
        let label = customer.description;
        for (let n = 0; n < depth; ++n) {
            label = '\xA0\xA0\xA0\xA0' + label;
        }
        const cust = customer as unknown as TreeCustomer;
        cust.treeLabel = label;
        cust.depth = depth;
        result.push(cust);

        // Note we are passing actual Customers, but we will add the missing data later
        // @ts-ignore
        result = [...result, ...recSort(customerByParent[customer.id], customerByParent, depth + 1)];
    }
    return result;
}

export function treefyCustomers(customers: CustomerDto[]): TreeCustomer[] {
    const validOwners = [...new Set(customers.map(c => c.id))];
    const customerByParent: { [key: string]: TreeCustomer[] } = {};
    for (const customer of customers) {
        if (customer.owner === customer.id || !validOwners.includes(customer.owner)) {
            continue;
        }
        if (!customerByParent[customer.owner]) {
            customerByParent[customer.owner] = [];
        }
        // Note that we don't have a customer, but we will add the missing information later
        customerByParent[customer.owner].push(customer as unknown as TreeCustomer);
    }
    const initial = customers.filter(c => c.owner === c.id || !(validOwners.includes(c.owner)));
    return recSort(initial, customerByParent, 0);


}
