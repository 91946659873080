import React, {ChangeEvent, MutableRefObject, useEffect, useState} from 'react';

import {LeafSensorData} from "../dbo/LeafSensorData";
import {DeviceDto} from "../dto/DeviceDto";
import {PlantDto} from "../dto/PlantDto";
import {LeafSensorService} from "../service/LeafSensorService";
import {ProfileDto} from "../dto/ProfileDto";
import {Gps} from "./Gps";
import {WeatherLocationDto} from "../dto/WeatherLocationDto";

interface PlantStatusProps {
    plant: PlantDto | null;
    profile: ProfileDto | null;
    device: DeviceDto | null;
    status: LeafSensorData | null;
    scrollReference: MutableRefObject<null>;
    onPlantSelected: (plant: PlantDto) => void;
    onWeatherStationShow: ( wl: WeatherLocationDto | undefined ) => void;
}


function renderLine(label: string, content: string | number | null) {
    return <div className="pf-label">
        <span className="pf-big pf-left">{label}</span>
        <span className="pf-big pf-right">{content}</span>
    </div>
}

function renderWeatherStation(plant: PlantDto, onWeatherStationShow: (wl: WeatherLocationDto | undefined) => void) {
    function onChange( event: ChangeEvent<HTMLInputElement> ) {
        if (event.target.checked) {
            onWeatherStationShow(plant.weatherLocation);
        } else {
            onWeatherStationShow(undefined);
        }
    }
    return <div className="pf-label">
        <span className="pf-big pf-left">Weather Station:</span>
        <span className="pf-big pf-right">{plant.weatherLocation?.description}
            {plant.weatherLocation?  <Gps coordinates={plant.weatherLocation.coordinates}></Gps> : null }
            (<small><label><input onChange={onChange} type="checkbox"/>Show on map</label></small>)</span>
    </div>

}

function renderHistoricalData(currentPlantId: string | undefined, plants: PlantDto[], onPlantSelected: (plant: PlantDto) => void) {
    function callback(id: string) {
        for (const p of plants) {
            if (p.id === id) {
                onPlantSelected(p);
            }
        }

    }

    plants.sort((a, b) => (new Date(b.regDate).getTime() - new Date(a.regDate).getTime()));
    return <div className="pf-label">
        <span className="pf-big pf-left">Historical data:</span>
        <span className="pf-big pf-right">
            <select value={currentPlantId} onChange={event => callback(event.target.value)}>
                {plants.map(p => <option key={p.id} value={p.id}>{p.description} - {p.regDate}</option>)}
            </select>

        </span>
    </div>

}

interface PlantStatusStatus {
    plants: PlantDto[];
}

const INITIAL_STATE: PlantStatusStatus = {
    plants: []
}

export function PlantStatus(props: PlantStatusProps) {
    const {plant, profile, status, scrollReference, device, onPlantSelected} = props;

    const [state, setState] = useState(INITIAL_STATE);

    useEffect(() => {
        if (props.device && props.device.id) {
            LeafSensorService.getPlantsByLeafSensorId(props.device.id).then(function (plants) {
                setState(state => ({...state, plants}));
            })
        }
    }, [props.device]);


    if (!plant || !device) {
        return <span/>;
    }

    function setWeatherStationVisibility(show: boolean) {

    }

    return <section className="pf-box" ref={scrollReference}>
        <h2>{plant.description}</h2>
        {status ?
            <div className="pf-labels">
                {renderLine('Profile', profile?.description || '')}
                {renderLine('Position', device.gps[1] + ' ' + device.gps[0])}
                {profile?.sensors.map( s => renderLine(s.description, (status as any)[s.id] + ' ' + (s.uom||''))) }
                {renderHistoricalData(plant.id, state.plants, onPlantSelected)}
                {renderWeatherStation(plant, props.onWeatherStationShow)}
                <div></div>
            </div>
            :
            <div className="pf-labels">
                {renderLine('Position', device.gps[1] + ', ' + device.gps[0])}
                {renderHistoricalData(plant.id, state.plants, onPlantSelected)}
                {renderWeatherStation(plant, props.onWeatherStationShow)}
            </div>
        }
    </section>
}
